<template>
	<div class="tabulation">
		<el-card>
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 标题 -->
					<el-form-item label="类型名称" prop="name">
						<div class="aaaa">
							<el-input v-model="form.name" placeholder="请填写标题" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 标题图片 -->
					<el-form-item label="主题图片  " prop="breviary">
						<el-upload action="/admin/model/saveImage" list-type="picture-card" limit="2" ref="qingkong"
							v-model="form.breviary" :auto-upload="true" :on-change="change" :on-error="bedefeated"
							:on-success="handleUpImage" :on-remove="Remove" :class="styA==0?'styB':'styC'"
							accept=".jpeg,.png,.jpg,.bmp,.gif" :before-upload="beforeImageUpload"
							:before-remove="beforeremove" :on-preview="onpreview" name="imageFile" :headers="header">
							<el-icon>
								<Plus />
							</el-icon>
						</el-upload>

						<el-dialog v-model="dialogVisible">
							<img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%" />
						</el-dialog>
						<div class="referrals">
							( 推荐：图片尺寸 宽331px、高77px。)
						</div>
					</el-form-item>
					<!-- 排序 -->
					<el-form-item label="排序" prop="rank">
						<div class="aaaa">
							<el-input v-model="form.rank" type="number" placeholder="请填写排序值" size="large"></el-input>
						</div>
					</el-form-item>
					<el-form-item>
						<div class="submit">
							<el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading"
								@click="onSubmit">提交</el-button>
						</div>
					</el-form-item>
				</el-form>
			</div>

		</el-card>
	</div>
</template>
<script>
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		onBeforeUnmount,
		ref,
		shallowRef,
		onMounted,
		reactive
	} from 'vue'
	import {
		Plus,
	} from '@element-plus/icons-vue'
	import * as imageConversion from 'image-conversion'
	import {
		XJadd
	} from '../../utils/api'
	import qs from 'qs'
	import {
		ElMessage
	} from "element-plus";
	import {
		useRouter
	} from "vue-router";
	export default {
		name: "xjadd",
		components: {
			Plus,
		},
		setup() {
			// 按钮加载
			const ConfirmLoading = ref(false)
			// 上传图片
			// 上传图片
			const header = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			const styA = ref(0)
			const change = (res, fileList) => {
				styA.value = fileList.length
				form.breviary = fileList
				// console.log(fileList)
			}
			const beforeImageUpload = (File) => {
				console.log(File)
				let isLt2M = File.size / 1024 / 1024 < 1
				return new Promise((resolve) => {
					if (isLt2M) {
						resolve(File)
					}
					imageConversion.compressAccurately(File, 950).then((res) => {
						console.log(res);
						resolve(res);
					});
				})
			}
			// 上传失败
			const bedefeated = (err) => {
				console.log(err, '失败')
			}
			// 获取上传成功地址
			let location = ref('')
			// 上传成功，获取返回的图片地址
			const handleUpImage = (res) => {
				location.value = res.data
				console.log(res, '成功')
			}
			// 图片放大
			const dialogVisible = ref(false)
			let dialogImageUrl = ref('')
			const onpreview = (File) => {
				dialogVisible.value = true
				dialogImageUrl.value = File.url
				console.log(File)
			}
			// 删除图片
			const beforeremove = (File, fileList) => {
				console.log(File)
				console.log(fileList)
				styA.value = 0
			}
			const Remove = (file) => {
				console.log(file)
			}

			// 图片清空
			const qingkong = ref('')

			// 提交 按钮
			const router = useRouter();
			const onSubmit = () => {
				ruleFormRef.value.validate((valid) => {
					let data = {
						name: form.name,
						imageUrl: location.value,
						sort: form.rank
					}
					if (valid == true) {
						ConfirmLoading.value = true
						XJadd(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: "添加成功",
									type: "success",
									center: true,
								});
								setTimeout(() => {
									form.name = ''
									form.rank = ''
									location.value = ''
									qingkong.value.clearFiles()
									styA.value = 0
									router.push({
										name: 'advanced',
										params: {
											userId: 1
										}
									});
									ConfirmLoading.value = false
								}, 500)
							} else {
								ElMessage({
									message: res.data.msg,
									type: "error",
									center: true,
								});
								ConfirmLoading.value = false
							}
						})
						// console.log(data)
					} else {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
					console.log(location.value)
				})
			}
			// 编辑器实例，必须用 shallowRef
			const editorRef = shallowRef()


			// 模拟 ajax 异步获取内容
			onMounted(() => {})

			const editorConfig = {
				placeholder: '请输入内容...',
				MENU_CONF: {}
			}
			editorConfig.MENU_CONF['uploadImage'] = {
				// 上传图片的配置
			}

			// 组件销毁时，也及时销毁编辑器
			onBeforeUnmount(() => {
				const editor = editorRef.value
				if (editor == null) return
				editor.destroy()
			})
			// 标题验证
			const ruleFormRef = ref(null)
			const form = reactive({
				name: "",
				breviary: "",
				rank: ""
			})
			// 验证表单提示
			const rules = {
				breviary: [{
					required: true,
					message: "请上传缩略图",
					trigger: "change"
				}, ],
				name: [{
					required: true,
					message: "请填写类型名称",
					trigger: "blur"
				}, ],
			}
			return {
				qingkong,
				// 按钮加载
				ConfirmLoading,
				// 上传图片
				header,
				dialogVisible,
				dialogImageUrl,
				onpreview,
				beforeremove,
				Remove,
				styA,
				change,
				bedefeated,
				handleUpImage,
				beforeImageUpload,
				// handlePictureCardPreview,
				// 编辑器
				onSubmit,
				// 标题验证
				ruleFormRef,
				form,
				rules
			}
		}
	}
</script>
<style lang='scss' scoped>
	::v-deep .submit {
		margin-left: 80px;
	}

	::v-deep input::-webkit-outer-spin-button,
	::v-deep input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	.aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
		margin: auto;
		margin-top: 10px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 80px !important;
		font-weight: bold;
	}
</style>